import { useEffect, useState } from 'react';
import styles from './style.module.scss';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import ModerationButtons from 'components/atoms/ModerationButtons';
import ChangesLog from 'components/atoms/ChangesLog';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

type InputTypes = {
  label?: string;
  id: string;
  key?: string;
  disabled?: boolean;
  placeholder?: string;
  description?: string;
  value?: any;
  prevValue?: string;
  required?: boolean;
  fullWidth?: boolean;
  onChange?: any;
  onChangeApprove?: any;
  wasChanged?: boolean;
  hasNote?: boolean;
  approve?: boolean;
  approved?: boolean;
  changed?: boolean;
  dismiss?: boolean;
  hideLabel?: boolean;
};

export default function InputPhonePV({
  label = '',
  id,
  key,
  disabled,
  placeholder,
  description,
  value = '',
  prevValue,
  required,
  fullWidth = false,
  onChange,
  onChangeApprove,
  wasChanged,
  hasNote = false,
  approve = false,
  approved = false,
  changed = false,
  dismiss = false,
  hideLabel = false
}: InputTypes) {
  const previousValue = prevValue?.toString();
  const [showLog, setShowLog] = useState(false);
  const [val, setVal] = useState(value);
  const requiredLabels = label?.includes('*')
    ? label?.substring(label?.indexOf('*'), label?.lastIndexOf('*') + 1)
    : '';

  const applyClass = wasChanged
    ? approved
      ? styles.approved
      : dismiss
      ? styles.dismiss
      : changed
      ? styles.changed
      : styles.requireReview
    : approved
    ? styles.approved
    : dismiss
    ? styles.dismiss
    : '';

  // useEffect(() => {
  //   onChange(val);
  // }, [val]);

  return (
    <fieldset
      key={key}
      className={`${styles.fieldset} ${
        fullWidth ? styles.full_width : ''
      } grid`}
    >
      <div className={styles.labels_and_moderation}>
        {!hideLabel && (
          <label htmlFor={id} className={styles.label}>
            {`${label.replaceAll('*', '')}`}
            {required && (
              <span
                className={'text-red-500 ml-[2px] leading-[18px] text-[16px]'}
              >
                * {requiredLabels}
              </span>
            )}
            {hasNote && <span className={styles.hasNote}>{hasNote}</span>}
            {description && <small>{description}</small>}
          </label>
        )}
        {approve && wasChanged && (
          <ModerationButtons
            id={id}
            onChangeApprove={onChangeApprove}
            approved={approved}
            dismissed={dismiss}
          />
        )}
      </div>

      <div className={styles.container} key={key}>
        <PhoneInput
          className={applyClass}
          international
          countryCallingCodeEditable={false}
          countrySelectProps={{ unicodeFlags: true }}
          placeholder={placeholder}
          id={id}
          disabled={disabled}
          defaultCountry="US"
          value={val}
          onChange={(newValue) => onChange(newValue)}
        />

        {wasChanged && previousValue && (
          <ChangesLog
            showLog={showLog}
            setShowLog={setShowLog}
            prevValue={formatPhoneNumberIntl(previousValue)}
            className="absolute top-[20px] right-[16px]"
          />
        )}
      </div>
    </fieldset>
  );
}
